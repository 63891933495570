import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<inlineCode parentName="p">{`focusZone`}</inlineCode>{` behavior and `}<inlineCode parentName="p">{`useFocusZone`}</inlineCode>{` hook are used to designate a container where focus can be moved using keys other than `}<inlineCode parentName="p">{`Tab`}</inlineCode>{`. This is useful for implementing many of the patterns described in `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard"
      }}>{`Section 6`}</a>{` of the WAI-ARIA Authoring Practices document. The most common use case of this behavior is to allow arrow keys (up and down or left and right) to move focus between related elements, such as items in a menu.`}</p>
    <p>{`At a high level, the `}<inlineCode parentName="p">{`focusZone`}</inlineCode>{` behavior works by adjusting the `}<inlineCode parentName="p">{`tabindex`}</inlineCode>{` attribute on focusable elements and setting up event listeners on the container that respond to the relevant key presses.`}</p>
    <p>{`Settings for this behavior allow the user to customize several aspects of the focus zone. See `}<strong parentName="p">{`FocusZoneSettings`}</strong>{` below for a detailed description of these settings.`}</p>
    <h3>{`Focusability: which elements participate in the Focus Zone?`}</h3>
    <p>{`When the `}<inlineCode parentName="p">{`focusZone`}</inlineCode>{` behavior is established, it will discover all `}<em parentName="p">{`focusable`}</em>{` elements within the given container and allow them to be focused with the bound keys. `}<em parentName="p">{`Focusable`}</em>{` elements are those that either are normally focusable via the Tab key OR have a valid `}<inlineCode parentName="p">{`tabindex`}</inlineCode>{` attribute (including `}<inlineCode parentName="p">{`"-1"`}</inlineCode>{`). The easiest way to ensure an element participates in the focus zone is by applying the attribute `}<inlineCode parentName="p">{`tabindex="-1"`}</inlineCode>{`. If you need to prevent a focusable element from participating in the focus zone, you can provide a `}<inlineCode parentName="p">{`focusableElementFilter`}</inlineCode>{`.`}</p>
    <h3>{`Focus order`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`focusZone`}</inlineCode>{` behavior uses the natural DOM ordering of elements to determine focus order. This means that a key that moves focus will either move to a "next" element or to a "previous" element. For example, the left arrow key and the up arrow key would both move focus to the previous element in the DOM order, while the right arrow key and the down arrow key would both move focus to the next element in the DOM order.`}</p>
    <p>{`Focus cannot be moved beyond the last element of the container (other than using the Tab key). The `}<inlineCode parentName="p">{`focusOutBehavior`}</inlineCode>{` option can be used to allow focus to wrap around from last to first element (or vice-versa).`}</p>
    <p>{`For a more customized focus movement behavior, the consumer has the ability to supply a custom callback that identifies the next element to focus.`}</p>
    <h4>{`Entering the focus zone`}</h4>
    <p>{`By default, when focus enters a focus zone, the element that receives focus will be the most recently-focused element within that focus zone. If no element had previously been focused, or if that previously-focused element was removed, focus will revert to the first focusable element within the focus zone, regardless of the direction of focus movement.`}</p>
    <p>{`Using the `}<inlineCode parentName="p">{`focusInStrategy`}</inlineCode>{` option, you can change this behavior. Setting this option to `}<inlineCode parentName="p">{`"first"`}</inlineCode>{` will simply cause the first focusable element in the container to be focused whenever focus enters the focus zone. Setting it to `}<inlineCode parentName="p">{`"closest"`}</inlineCode>{` will cause either the first or last focusable element in the container to be focused depending on the direction of focus movement (for example, a shift+tab that brings focus to the container will cause the last focusable element to be focused, whereas a regular tab would cause the first focusable element to be focused). Otherwise, you may provide a callback to choose a custom element to receive initial focus. One scenario where this would be useful is if you wanted to focus an item that is "selected" in a list.`}</p>
    <p>{`For more information on choosing the right focus in behavior, see `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-practices-1.1/#kbd_general_within"
      }}>{`6.6 Keyboard Navigation Inside Components`}</a>{` from the ARIA Authoring Practices document.`}</p>
    <h3>{`Supported keys`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`focusZone`}</inlineCode>{` behavior supports different sets of keys for moving focus around. The `}<inlineCode parentName="p">{`bindKeys`}</inlineCode>{` option is used to set which of the following keys can be used to move focus.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Key(s)`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Notes`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Use case`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ArrowVertical`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Prevents default behavior of scrolling where applicable`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Most focus zones with vertically-positioned elements`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ArrowHorizontal`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Prevents default behavior of scrolling where applicable`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Most focus zones with horizontally-positioned elements`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`HomeAndEnd`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Causes focus to jump to the first or last focusable item in the container. Does not move focus if the currently-focused element is a text box.`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Generally used with arrow keys`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`PageUpDown`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Works the same as the Home and End keys. Advisable only when supplying a custom callback that supports paging.`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`In a long, scrollable list`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Tab/Shift+Tab`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unlike other keys, the Tab key will always allow movement outside of the focus zone (use the Focus Trap behavior to prevent this). Tab moves to the next item, Shift+Tab moves to the previous.`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Bind Tab if you want to continue allowing tab to move focus between elements in your container rather than jumping out of the container.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`JK`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`J moves focus to the next item, K moves to the previous. Does not move focus if the currently-focused element is a text box. `}<a parentName="td" {...{
              "href": "https://catonmat.net/why-vim-uses-hjkl-as-arrow-keys"
            }}>{`Originally from`}</a>{` the vi keybindings`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used in certain lists`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`HL`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`H moves focus to the previous item, L moves to the next. Does not move focus if the currently-focused element is a text box. `}<a parentName="td" {...{
              "href": "https://catonmat.net/why-vim-uses-hjkl-as-arrow-keys"
            }}>{`Originally from`}</a>{` the vi keybindings`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used in certain lists`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`WS`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`W moves focus to the previous item, S moves to the next. Does not move focus if the currently-focused element is a text box.`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Any situation where it is more ergonomic for the left hand to perform this action, such as in a gaming context (rare)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`AD`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A moves focus to the previous item, D moves to the next. Does not move focus if the currently-focused element is a text box.`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Any situation where it is more ergonomic for the left hand to perform this action, such as in a gaming context (rare)`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`DOM Focus vs. Active Descendant`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`focusZone`}</inlineCode>{` behavior supports two modes of operation: DOM Focus and Active Descendant.`}</p>
    <ul>
      <li parentName="ul">{`DOM Focus is the default mode and by far the most commonly needed. When a key is used to move focus, we call `}<inlineCode parentName="li">{`.focus()`}</inlineCode>{` directly on the element to receive focus. This results in `}<inlineCode parentName="li">{`document.activeElement`}</inlineCode>{` getting set to this new element, and it will receive any necessary styles via `}<inlineCode parentName="li">{`:focus`}</inlineCode>{` and `}<inlineCode parentName="li">{`:focus-within`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Active Descendant mode does `}<em parentName="li">{`not`}</em>{` move DOM focus. Instead, focus remains on the `}<em parentName="li">{`control`}</em>{` element, and its `}<inlineCode parentName="li">{`aria-activedescendant`}</inlineCode>{` attribute is set to the ID of the relevant element. Because there are no `}<inlineCode parentName="li">{`:focus`}</inlineCode>{` styles applied and no `}<inlineCode parentName="li">{`focus`}</inlineCode>{` events fired, you can supply an `}<inlineCode parentName="li">{`onActiveDescendantChanged`}</inlineCode>{` callback to handle any necessary styles or other logic as the active descendant changes. For more information on the Active Descendant focus pattern, see `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.1/#kbd_focus_activedescendant"
        }}>{`6.6.2 Managing Focus in Composites Using `}<inlineCode parentName="a">{`aria-activedescendant`}</inlineCode></a>{` from the ARIA Authoring Practices document.`}</li>
    </ul>
    <h3>{`Demo`}</h3>
    <p>{`Deploy Storybook to see live demos of `}<inlineCode parentName="p">{`focusZone`}</inlineCode>{`.`}</p>
    <h3>{`Usage`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const settings = {
  bindKeys: FocusKeys.ArrowVertical | FocusKeys.HomeAndEnd,
} as FocusZoneSettings
const focusZone = document.getElementById('focusZoneContainer')
focusZone(focusZone, settings)
`}</code></pre>
    <h3>{`API`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`focusZone`}</inlineCode>{` function takes the following arguments.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`container`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Element`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The focus zone will apply to this container and all of its focusable descendants.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`settings`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`FocusZoneSettings`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`{}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Settings to customize the focus zone. See below for a description of each setting.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`FocusZoneSettings interface`}</h4>
    <p><inlineCode parentName="p">{`FocusZoneSettings`}</inlineCode>{` is an object with the following interface. All properties are optional and have default behaviors.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`bindKeys`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`FocusKeys`}</inlineCode>{` (numeric enum)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`FocusKeys.ArrowVertical │ FocusKeys.HomeAndEnd`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Bit flags that identify keys that will move focus around the focus zone. Each available key either moves focus to the "next", "previous", "start", or "end" element, so it is best to only bind the keys that make sense to move focus in your UI. Use the `}<inlineCode parentName="td">{`FocusKeys`}</inlineCode>{` object to discover supported keys (listed in the "Supported keys" section above). `}<br /><br />{`Use the bitwise "OR" operator (`}{`|`}{`) to combine key types. For example, `}<inlineCode parentName="td">{`FocusKeys.WASD │ FocusKeys.HJKL`}</inlineCode>{` represents all of W, A, S, D, H, J, K, and L.`}<br /><br />{`The default for this setting is `}<inlineCode parentName="td">{`FocusKeys.ArrowVertical │ FocusKeys.HomeAndEnd`}</inlineCode>{`, unless `}<inlineCode parentName="td">{`getNextFocusable`}</inlineCode>{` is provided, in which case `}<inlineCode parentName="td">{`FocusKeys.ArrowAll │ FocusKeys.HomeAndEnd`}</inlineCode>{` is used as the default.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`focusOutBehavior`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"stop" │ "wrap"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`"stop"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Choose the behavior applied in cases where focus is currently at either the first or last element of the container. `}<inlineCode parentName="td">{`"stop"`}</inlineCode>{` - do nothing and keep focus where it was; `}<inlineCode parentName="td">{`"wrap"`}</inlineCode>{` - wrap focus around to the first element from the last, or the last element from the first`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`focusInStrategy`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"first" │ "closest" │ "previous" │ Function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`"previous"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This option allows customization of the behavior that determines which of the focusable elements should be focused when focus enters the container via the Tab key.`}<br /><br />{`When set to `}<inlineCode parentName="td">{`"first"`}</inlineCode>{`, whenever focus enters the container via Tab, we will focus the first focusable element. When set to `}<inlineCode parentName="td">{`"previous"`}</inlineCode>{`, the most recently focused element will be focused (fallback to first if there was no previous).`}<br /><br />{`The "closest" strategy works like "first", except either the first or the last element of the container will be focused, depending on the direction from which focus comes.`}<br /><br />{`If a function is provided, this function should return the `}<inlineCode parentName="td">{`HTMLElement`}</inlineCode>{` intended to receive focus. This is useful if you want to focus the currently "selected" item or element.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`getNextFocusable`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This is a callback used to customize the next element to focus when a bound key is pressed. The function takes 3 arguments: `}<inlineCode parentName="td">{`direction`}</inlineCode>{` (`}<inlineCode parentName="td">{`"previous"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"next"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"start"`}</inlineCode>{`, or `}<inlineCode parentName="td">{`"end"`}</inlineCode>{`), `}<inlineCode parentName="td">{`from`}</inlineCode>{` (Element or `}<inlineCode parentName="td">{`undefined`}</inlineCode>{`), and `}<inlineCode parentName="td">{`event`}</inlineCode>{` (KeyboardEvent). The function should return the next element to focus, or `}<inlineCode parentName="td">{`undefined`}</inlineCode>{`. If `}<inlineCode parentName="td">{`undefined`}</inlineCode>{` is returned, the regular algorithm to select the next element to focus will be used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`focusableElementFilter`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This is a callback used to cull focusable elements from participating in the focus zone.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      